//
// breadcrumb.scss
//

//Breadcrumb
.pages-heading {
  .title {
    font-size: 32px !important;
    letter-spacing: 1px;
  }
}

.position-breadcrumb {
  position: absolute;
  top: 100%;
  right: 12px;
  left: 12px;
  transform: translateY(-50%);
  text-align: center;
  z-index: 1;
}

.breadcrumb {
  letter-spacing: 0.5px;
  padding: 8px 24px;
  display: block;
  .breadcrumb-item {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    padding-left: 0;
    display: inline-block;
    a {
      color: $dark;
      &:hover {
        color: $primary;
      }
    }
    &.active {
      color: $primary;
    }
    &:before {
      content: '';
    }
    &:after {
      content: '\F0142' !important;
      font-size: 14px;
      color: $dark;
      font-family: 'Material Design Icons';
      padding-left: 5px;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}
