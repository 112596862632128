//
// home.scss
//

/*********************************/
/*         Home & Hero           */
/*===============================*/

@mixin home-common {
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}
.bg-home {
  height: 100vh;
  @include home-common();
}
.bg-home-75vh {
  height: 75vh !important;
  @include home-common();
  @media (max-width: 767px) {
    height: 80vh !important;
  }
}
.bg-half-260 {
  padding: 260px 0;
  @include home-common();
}
.bg-half-170 {
  padding: 170px 0;
  @include home-common();
}

.bg-half-100 {
  padding: 100px 0;
  @include home-common();
}
.bg-half {
  padding: 170px 0;

  @media (max-width: 768px) {
    padding: 160px 0 60px;
  }
  @include home-common();
}

.bg-auth-home {
  padding: 142px 0;
  @include home-common();
}

//Gradient Home
.bg-circle-gradiant {
  background: radial-gradient(
    circle at 50% 50%,
    rgba($primary, 0.1) 0%,
    rgba($primary, 0.1) 33.333%,
    rgba($primary, 0.2) 33.333%,
    rgba($primary, 0.2) 66.666%,
    rgba($primary, 0.3) 66.666%,
    rgba($primary, 0.3) 99.999%
  );
}

//It Solutions
.it-home {
  animation: slideleft 30000s infinite linear;
}
@keyframes slideleft {
  from {
    background-position: 0%;
  }
  to {
    background-position: 90000%;
  }
}
//Saas
.home-dashboard {
  img {
    position: relative;
    top: 60px;
    z-index: 1;
  }

  //Index-onepage.html
  @media (min-width: 768px) {
    &.onepage-hero {
      overflow: hidden;
    }
  }
}

.classic-saas-image .bg-saas-shape {
  &:after {
    bottom: 3rem;
    left: 0;
    width: 75rem;
    height: 55rem;
    border-radius: 20rem;
    @media (max-width: 767px) {
      left: 10rem;
      width: 30rem;
      height: 30rem;
      border-radius: 10rem;
    }
  }
}

.classic-saas-image .bg-saas-shape,
.freelance-hero .bg-shape {
  img {
    @media (max-width: 768px) {
      max-width: 550px;
    }

    @media (max-width: 767px) {
      max-width: 100%;
      height: auto;
    }
  }
}

.classic-saas-image .bg-saas-shape,
.freelance-hero .bg-shape {
  &:after {
    opacity: 0.9;
    transform: rotate(130deg);

    @media (max-width: 767px) {
      bottom: -5rem !important;
      height: 30rem;
    }
  }
}

// Freelancer Home
.freelance-hero .bg-shape {
  &:after {
    bottom: 0rem;
    left: 7rem;
    width: 100rem;
    height: 70rem;
    border-radius: 6rem;
    @media (max-width: 768px) {
      bottom: -10rem;
    }
    @media (max-width: 767px) {
      left: 15rem;
      width: 40rem;
      height: 30rem;
    }
  }
}

.freelance-hero .bg-shape {
  &:after {
    box-shadow: 0 0 40px rgba($primary, 0.5);
    background-color: lighten($primary, 25%);
  }
}

//Studio
.studio-home {
  padding: 7%;
}

//Agency
.agency-wrapper {
  &:before {
    content: '';
    position: absolute;
    bottom: 22rem;
    right: 54rem;
    width: 90rem;
    height: 35rem;
    border-radius: 17rem;
    transform: rotate(45deg);
    background-color: $light;
    z-index: -1;

    @media (max-width: 768px) {
      right: 12rem;
    }

    @media (max-width: 767px) {
      right: 0;
    }
  }
}

//Marketing
.bg-marketing {
  padding: 76px 0 120px;
  @include home-common();
}

//Coworking Landing / Hotal / Shop
.home-slider {
  .carousel-control-next,
  .carousel-control-prev {
    width: 30px;
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 30px;
    border: 1px solid $border-color;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 30%;
  }

  .carousel-control-next {
    right: 8px;
  }

  .carousel-control-prev {
    left: 8px;
  }
}

.carousel-indicators {
  [data-bs-target] {
    width: 10px;
    height: 10px;
    border-radius: 3px;
    transition: all 0.5s ease;
    border: 0;
    background: $primary !important;
    margin: auto 4px;
  }
  .active {
    background-color: $primary !important;
    transform: rotate(45deg);
  }
}

.bg-animation-left {
  &:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 70%;
    height: 100%;
    background: rgba($animation-bg, 0.85);
    clip-path: polygon(0 0, 90% 0, 70% 100%, 0% 100%);

    @media (max-width: 767px) {
      width: 100%;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
  }
  &.dark-left {
    &:after {
      background: rgba($dark, 0.6);
    }
  }
  &.crypto-home {
    &:after {
      background: $primary;
      width: 80%;
      clip-path: polygon(0 0, 100% 0, 50% 100%, 0% 100%);
    }
  }
  &.task-management-home {
    &:after {
      background: url('../../../images/task/bg.png') $primary;
      width: 100%;
      clip-path: circle(65% at 40% 25%);
    }
  }
}

//page-invoice.html
.bg-invoice {
  padding: 100px 0;
  @include home-common();
}

//Application
.classic-app-image {
  .bg-app-shape {
    &:after {
      bottom: 3rem;
      left: -8rem;
      width: 55rem;
      height: 35rem;
      border-radius: 20rem;
      transform: rotate(120deg);
      opacity: 0.9;
    }
  }
  .app-images {
    position: absolute;
    bottom: 60px;
    right: -20px;
  }
}

.classic-app-image .bg-app-shape,
.classic-saas-image .bg-saas-shape,
.freelance-hero .bg-shape {
  &:after {
    content: ' ';
    position: absolute;
    z-index: -1;
  }
}
.classic-app-image .bg-app-shape,
.classic-saas-image .bg-saas-shape {
  &:after {
    box-shadow: 0 0 40px rgba($primary, 0.5);
    background-color: $primary;
  }
}

//Landing one
.shape-before {
  .shape-img {
    position: absolute;
    top: -50px;
    left: -35px;
    z-index: -1;
    height: auto;
    overflow: hidden;
  }
}

//corporate business
.swiper-slider-hero {
  .swiper-container,
  .swiper-slide .slide-inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .swiper-container {
    .swiper-button-prev,
    .swiper-button-next {
      background: transparent;
      width: 35px;
      height: 35px;
      line-height: 35px;
      margin-top: -30px;
      &:before {
        font-family: 'Material Design Icons';
        font-weight: 900;
        color: $white;
      }
      &:hover {
        background: $primary;
        border-color: $primary !important;
      }
    }
    .swiper-button-prev {
      left: 10px;
      border: 1px solid rgba($white, 0.5);
      &:before {
        content: '\f0141';
      }
    }
    .swiper-button-next {
      right: 10px;
      border: 1px solid rgba($white, 0.5);
      &:before {
        content: '\f0142';
      }
    }
  }
  .swiper-pagination-bullet {
    color: $gray-100;
    background: transparent;
  }
  .swiper-pagination-bullet-active {
    color: $white;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 45px;
  }
  .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 13px;
  }
}

// Responsive
@media (min-width: 769px) {
  //studio
  .bg-studio {
    &:after {
      content: '';
      position: absolute;
      right: 0px;
      top: 0px;
      width: 50%;
      height: 100%;
      background: $white none repeat scroll 0 0;
      z-index: 2;
    }
  }
}

@media (max-width: 767px) {
  .bg-home,
  .bg-half-170,
  .bg-half-260,
  .bg-marketing,
  .swiper-slider-hero .swiper-container .swiper-slide {
    padding: 150px 0;
    height: auto;
  }
}

//Bg Video Hero
.bg-video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
  iframe {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

//Shapes
.shape {
  position: absolute;
  pointer-events: none;
  right: 0;
  bottom: -2px;
  left: 0;
  & > svg {
    transform: scale(2);
    width: 100%;
    height: auto;
    transform-origin: top center;
  }

  &.integration-hero {
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    height: 250px;
    background: $shape-bg;

    @media (max-width: 768px) {
      height: 140px;
    }

    @media (max-width: 425px) {
      height: 60px;
    }
  }

  @media (max-width: 425px) {
    bottom: -4px;
  }
}
