//
// _blockquote.scss
//

.blockquote {
  border-left: 3px solid $primary;
  border-radius: 6px;
  font-size: 16px;
}

.blockquote-wrapper {
  .blockquote {
    position: relative;
    align-self: center;
    border: 2px solid $primary;
    &:after {
      content: '';
      position: absolute;
      border: 2px solid $primary;
      border-radius: 0 50px 0 0;
      width: 60px;
      height: 60px;
      bottom: -60px;
      left: 50px;
      border-bottom: none;
      border-left: none;
      z-index: 3;
    }
    &:before {
      content: '';
      position: absolute;
      width: 80px;
      border: 6px solid $body-bg;
      bottom: -3px;
      left: 50px;
      z-index: 2;
    }
  }

  .author {
    position: relative;
    margin-left: 150px;
    &:first-letter {
      margin-left: -12px;
    }
  }
}
